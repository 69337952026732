import type { EventProfileRouteParams } from 'modules/Events/types';
import type { AppRoute } from 'utils/routes';

const eventProfile: AppRoute<EventProfileRouteParams> = {
  path: `/:id/events/:eventId`,
  route: ({ id, eventId }) => `/${id}/events/${eventId}`
};

export const eventsRoutes = {
  eventProfile
};
