import { eventsRoutes } from 'modules/Events/utils/routes';
import { outletsRoutes } from 'modules/Outlets/utils/routes';
import type {
  FitSurveyRouteParams,
  OutletSurveyRouteParams,
  OutletSurveysRouteParams,
  RegularSurveyRouteParams,
  RegularSurveysRouteParams,
  SurveySubmissionDetailsRouteParams,
  SurveySubmissionsHistoryRouteParams,
  SurveysHistoryRouteParams
} from 'modules/Surveys/types';
import type { AppRoute } from 'utils/routes';

const outletSurveys: AppRoute<OutletSurveysRouteParams> = {
  path: '/outlet-surveys',
  route: '/outlet-surveys',
  baseRoute: outletsRoutes.outletProfile
};

const outletSurvey: AppRoute<OutletSurveyRouteParams> = {
  path: `/:surveyId`,
  route: ({ surveyId }) => `/${surveyId}`,
  baseRoute: outletSurveys
};

const surveysHistory: AppRoute<SurveysHistoryRouteParams> = {
  path: `/surveys-history/:surveyType(fit|regular|outlet)`,
  route: ({ surveyType }) => `/surveys-history/${surveyType}`,
  baseRoute: outletsRoutes.outletProfile
};

const surveySubmissionsHistory: AppRoute<SurveySubmissionsHistoryRouteParams> = {
  path: '/:surveyId/submissions',
  route: ({ surveyId }) => `/${surveyId}/submissions`,
  baseRoute: surveysHistory
};

const surveySubmissionDetails: AppRoute<SurveySubmissionDetailsRouteParams> = {
  path: surveySubmissionsHistory.path + '/:submissionId',
  route: ({ submissionId, surveyId }) => `/${surveyId}/submissions/${submissionId}`,
  baseRoute: surveysHistory
};

const regularSurveys: AppRoute<RegularSurveysRouteParams> = {
  path: '/regular-surveys',
  route: '/regular-surveys',
  baseRoute: eventsRoutes.eventProfile
};

const regularSurvey: AppRoute<RegularSurveyRouteParams> = {
  path: `/:surveyId`,
  route: ({ surveyId }) => `/${surveyId}`,
  baseRoute: regularSurveys
};

const fitSurvey: AppRoute<FitSurveyRouteParams> = {
  path: '/fit-survey',
  route: '/fit-survey',
  baseRoute: eventsRoutes.eventProfile
};

export const surveysRoutes = {
  fitSurvey,
  outletSurvey,
  outletSurveys,
  regularSurvey,
  regularSurveys,
  surveysHistory,
  surveySubmissionDetails,
  surveySubmissionsHistory
};
