import type { OutletProfileRouteParams } from 'modules/Outlets/types';
import type { AppRoute } from 'utils/routes';

const outlets: AppRoute<{}> = {
  path: '/outlets',
  route: '/outlets'
};

const outletProfile: AppRoute<OutletProfileRouteParams> = {
  path: `/customer/:id`,
  route: ({ id }) => `/customer/${id}`
};

export const outletsRoutes = {
  outletProfile,
  outlets
};
